import UserOrderTable from "../orders/UserOrderTable";
import { TANSTACK_USER_ORDERS_KEY, useUserOrders } from "../../hooks/useUserOrders";
import { queryClient } from "../../App";
import Loading from "../utils/Loading";
import { DOWNLOAD_INVOICE, INVOICING_AND_PAYMENT, LICENSE_TECHNICAL_NAME, ORDER_ID, ORDER_TIME, SHOW_BASKET, STATUS, UPLOAD_DOCUMENT } from "../orders/UserOrderTableColumns";

function AseclaAdminOrders() {
    const {userOrders, loaded } = useUserOrders(true);

    const refresh = (): void => {
        queryClient.invalidateQueries({ queryKey: [TANSTACK_USER_ORDERS_KEY] })
    }
        
    return <div id="userOrders">
        {!loaded && <Loading></Loading>}
        {loaded && <UserOrderTable 
            orders={userOrders ?? []}
            aseclaAdminModule={true}
            refresh={refresh}
        ></UserOrderTable>}
    </div>
}
export default AseclaAdminOrders;