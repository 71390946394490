import React from "react";
import AseclaDataContext from "../../../store/AseclaDataContext";
import { ContextProps } from "../../../type/ContextProps";
import { PropertyQuestion } from "../PropertyQuestion";
import { Trans, useTranslation } from 'react-i18next';
import { LicenseType } from "../../../type/LicenseType";
import AseclaButton from "../../utils/AseclaButton";
import { BonusCode } from "../../../type/BonusCode";
import BonusCodeElement from "../../utils/BonusCodeElement";
import UnitDiv from "../../utils/UnitDiv";
import InvoiceData from "../../../type/InvoiceData";
import InvoiceDataForm from "../../InvoiceDataForm";
import LogInOrRegister from "../LogInOrRegister";
import PaymentMethod, { PAYMENT_METHOD_BANK_TRANSFER } from "../PaymentMethod";
import { useCreateLicenseCodeOrder, useOrderLicenseCode } from "../../../hooks/mutations/useTargetOrder";
import Price from "../Price";
import IsManager from "../../checks/IsManager";
import OrderLicenseCodeRequest from "../../../type/request/OrderLicenseCodeRequest";
import CreateOrderRequest from "../../../type/request/CreateOrderRequest";
import styled from "styled-components";

type ConfirmOrderParams = {
    licType?: LicenseType,
    licTypePropValue?: PropertyQuestion[],
    quantity?: number,
    length?: number,
    lengthUnit?: string,
    orderId?: number,

    bonusCodeToForce?: BonusCode|null,
    orderStatus?: string,
    hideBonusCode?: boolean,
}

function ConfirmOrder({
            licType,
            licTypePropValue,
            quantity,
            length,
            lengthUnit,
            orderId,
            bonusCodeToForce = null,
            orderStatus,
            hideBonusCode = false,
        }: ConfirmOrderParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const {t, i18n} = useTranslation();
    const [orderSent, setOrderSent] = React.useState<boolean>(false);

    const [shopConsent, setShopConsent] = React.useState<boolean>(false);
    const [useMailForOrderConsent, setUseMailForOrderConsent] = React.useState<boolean>(false);

    const [bonusCodeEnableBuy, setBonusCodeEnableBuy] = React.useState<boolean>(false);
    const [bonusCodeValid, setBonusCodeValid] = React.useState<boolean>(false);
    const [bonusCode, setBonusCode] = React.useState<BonusCode|null>(bonusCodeToForce);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<string>(PAYMENT_METHOD_BANK_TRANSFER);
    
    const [companyName, setCompanyName] = React.useState<string>("");
    const [taxId, setTaxId] = React.useState<string>("");
    const [address, setAddress] = React.useState<string>("");
    const [city, setCity] = React.useState<string>("");
    const [zipCode, setZipCode] = React.useState<string>("");
    const [country, setCountry] = React.useState<string>("");

    const { createLicenseCodeOrder, isLoading: creatingLicenseCodeOrder } = useCreateLicenseCodeOrder(setOrderSent);
    const { orderLicenseCode, isLoading: orderingLicenseCode } = useOrderLicenseCode(setOrderSent);

    React.useEffect(() => {
        let invoiceData: InvoiceData|undefined = props.getCurrentOrganization()?.invoiceData;
        if (invoiceData != null) {
            setCompanyName(invoiceData.companyName);
            setTaxId(invoiceData.taxId);
            setAddress(invoiceData.address);
            setCity(invoiceData.city);
            setZipCode(invoiceData.zipCode);
            setCountry(invoiceData.country);
        }
    }, [props.getCurrentOrganization()]);

    React.useEffect(() => {
        props.setDisableOrganizationSwitch(orderSent);
        return () => {
            props.setDisableOrganizationSwitch(false);
        };
    }, [orderSent]);

    const orderLicenseCodeClick = (): void => {
        let invoiceData: InvoiceData = {
            companyName: companyName,
            taxId: taxId,
            address: address,
            city: city,
            zipCode: zipCode,
            country: country,
        }
        if (orderId) {
            let req: OrderLicenseCodeRequest = {
                orderId: orderId,
                paymentMethod: selectedPaymentMethod,
                invoiceData: invoiceData,
                organizationId: props.getCurrentOrganization()?.id!,
                language: i18n.language,
                bonusCodeId: (!bonusCodeValid || bonusCode === null) ? null : bonusCode.id,
                bonusCodeValue: (!bonusCodeValid || bonusCode === null) ? null : bonusCode.theCode,
            };
            //If anonymous order was created before or Asecla Admin created an order
            orderLicenseCode(req);
        } else {
            //In case we are filling order request that has not been created on server yet
            let req: CreateOrderRequest = props.pricing.createOrderRequest(
                licTypePropValue, licType!, quantity!, length!, lengthUnit!, invoiceData, selectedPaymentMethod, bonusCodeValid ? bonusCode : null);
            createLicenseCodeOrder(req);
        }
    }

    const enableBuyButton: boolean = (useMailForOrderConsent && shopConsent && bonusCodeEnableBuy);

    if (orderSent) {
        return <p>{t("You order has been sent. Thank you!") as string}</p>;
    }

    //Other statuses are: Sketch and CreatedForUser
    if (orderStatus === "Raised") {
        return <p>{t("Your order has already been raised. Please await Asecla Team contact or contact us in case of any problems.") as string}</p>
    }
    if (orderStatus === "Rejected") {
        return <p>{t("We are sorry, but this order has been rejected. Create new one or contact Asecla Team in case of questions.") as string}</p>
    }
    if (orderStatus === "AwaitingPayment") {
        return <p>{t("Your order has already been raised and we are awaiting the payment.") as string}</p>
    }
    if (orderStatus === "Paid") {
        return <p>{t("Your order has already been raised and we got the payment. You should receive your license.") as string}</p>
    }
    if (orderStatus === "CodeSent") {
        return <p>{t("Your order has been been payed and we have sent you your license. Please contact us in case of any issues.") as string}</p>
    }

    interface ProductPropertyParam {
        licType: LicenseType
    }
    const ProductProperty = ({licType}: ProductPropertyParam) => {
        return <>
            {licType.product.properties.map((prop, index) => <li key={"fixed_property_" + index}>
                {prop.labels[i18n.language]}: {licType.properties[prop.propertyId]}
            </li>)}
        </>
    }

    const freezePage = (): boolean => {
        return props.freezeHeaderCounter > 0;
    }

    if (licType === undefined) {
        return <><h3 className="asecla-heading">{t("Select license you would like to receive") as string}</h3>
            <Trans i18nKey="order_for" components={{ unit: <UnitDiv lengthUnit={"Day"} lowercase={true}></UnitDiv>}} values={{ length: length, quantity: quantity }} />
        </>
    } else {
        if (props.authentication.isAuthenticated()) {
            let email = props.authentication.getUserMail();
            return <>
                <h3 className="asecla-heading">{t("Order summary") as string}</h3>
                <IsManager><>
                    <LeftAlignedDIV>{t("You are about to order:") as string}
                        <ul>
                            <li>
                                <Trans i18nKey="license_for_product"
                                    components={{license: <u></u>,
                                                product:<u></u>
                                                }}
                                    values={{licenseName: licType.names[i18n.language],
                                            productName: !licType.product ? "???" : licType.product.names[i18n.language]
                                    }}
                                ></Trans>
                            </li>
                            <li>
                                <Trans i18nKey="order_length_item"
                                    components={{unit: <UnitDiv lengthUnit={lengthUnit} lowercase={true}></UnitDiv>}}
                                    values={{length: length}}
                                ></Trans>
                            </li>
                            <li>
                                <Trans i18nKey="order_quantity_item"
                                    components={{}}
                                    values={{quantity: quantity}}
                                ></Trans>
                            </li>
                            {((licType.product.properties.length > 0) || (licTypePropValue && licTypePropValue!.length > 0)) && 
                                <li> {t("and following properties") as string}:<ul>
                                    {licTypePropValue && licTypePropValue!.length > 0 && <>
                                        {licTypePropValue!.map((property) => <li key={"custom_property_" + property.propertyId}>
                                            {licType!.product.properties[property.productPropertyIndex].labels[i18n.language]}: {property.value}
                                        </li>)}
                                    </>}
                                    <ProductProperty licType={licType}></ProductProperty>
                                </ul></li>
                            }
                            <li>{t("It will cost you ") as string}<b>
                                <Price length={length!} licenseType={licType!} quantity={quantity!} showHint={false} showDiscount={true}
                                    bonusCode={(bonusCodeValid ? bonusCode : null) ?? undefined} bonusCodeValid={bonusCodeValid} displayNetAndBrutto={true}                        
                                ></Price>
                            </b></li>
                        </ul>
                    </LeftAlignedDIV>
                    <div>
                        
                        
                        {t("We will send you Pro-forma invoice once you request an order. After paying Pro-forma invoice we will send you the License Code. We will use the email: {email} as contact email.", {mail: email}) as string}
                        <div>
                            {t("Please contact us in case of problems or questions.") as string}
                        </div>
                        <div>
                            <h4 className="asecla-heading">{t("Invoice data") as string}</h4>
                            <InvoiceDataForm
                                taxId={taxId} setTaxId={setTaxId}
                                companyName={companyName} setCompanyName={setCompanyName}
                                address={address} setAddress={setAddress}
                                city={city} setCity={setCity}
                                zipCode={zipCode} setZipCode={setZipCode}
                                country={country} setCountry={setCountry}
                                validationError={null}
                                readOnly={true}
                            ></InvoiceDataForm>
                        </div>
                        <br></br>
                        <PaymentMethod selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod}/>
                        <br></br>
                        <div>
                            <BonusCodeElement bonusCodeToForce={bonusCodeToForce!} licType={licType}
                                setBonusCodeEnableBuy={setBonusCodeEnableBuy} setBonusCodeValid={setBonusCodeValid} bonusCode={bonusCode} setBonusCode={setBonusCode} includeBonusCodeInput={!hideBonusCode}></BonusCodeElement>
                        </div>
                        <div>
                            <input type="checkbox" checked={shopConsent} onChange={e => setShopConsent(e.target.checked)} disabled={freezePage()} name="shopConsent" id="shopConsent"/>
                            &nbsp;
                            <label htmlFor="shopConsent">{t("I have read and accept the store regulations") as string}</label>
                        </div>
                        <div>
                            <input type="checkbox" checked={useMailForOrderConsent} onChange={e => setUseMailForOrderConsent(e.target.checked)} disabled={freezePage()} name="useMailForOrderConsent" id="useMailForOrderConsent"/>
                            &nbsp;
                            <label htmlFor="useMailForOrderConsent">
                                <Trans i18nKey="use_contact_information"
                                    values={{
                                        toMakeTransaction: t("in order to make the transaction")
                                    }}
                                    components={{bolded: <b></b>}}/>
                            </label>
                        </div>
                        {(creatingLicenseCodeOrder || orderingLicenseCode) && <h3 className="asecla-heading" id="processingOrder">{t("We are processing your order, please don't close the browser now") as string}</h3>}
                        {props.freezeHeaderCounter === 0 && <AseclaButton className="cta" action={(e) => orderLicenseCodeClick()} enabled={enableBuyButton}>{t("Make order now and start payment") as string}</AseclaButton>}
                        
                    </div>
                </></IsManager>
            </>
        } else {
            return <>
                <h3 className="asecla-heading">{t("Select license you would like to receive") as string}</h3>
                <div><Trans i18nKey={"great_choice"} 
                        components={{license: <u></u>,
                            product: <u></u>,
                            units: <UnitDiv lengthUnit={lengthUnit} lowercase={true}></UnitDiv>,
                            props: <>{licTypePropValue && licTypePropValue.length > 0 && 
                                        <>{t("and following properties")}<br></br>{licTypePropValue.map((property) => 
                                            <div key={"property_" + property.propertyId}>
                                                {licType.product.properties[property.productPropertyIndex].labels[i18n.language]}: {property.value}
                                            </div>
                                          )}
                                        </>
                                      }
                                   </>,
                            cost: <b></b>
                        }}
                        values={{length: length,
                                 quantity: quantity,
                                 currency: "zł",
                                 finalCost: licType.price * length! * quantity!,
                                 licenseName: props.getLicenseTypeDisplayName(licType),
                                 productName: !licType.product ? "???" : licType.product.names["en"]}}
                    />
                </div>
                <LogInOrRegister
                    licType={licType}
                    length={length}
                    lengthUnit={lengthUnit}
                    licTypePropValue={licTypePropValue}
                    quantity={quantity}
                ></LogInOrRegister>
            </>
        }
    }
}
export default ConfirmOrder;

const LeftAlignedDIV = styled.div`
    ul {
        text-align: left;
    }
`