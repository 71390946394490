import './UserOrderTable.css'

import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { UserOrder, getLastInvoice } from "../../type/UserOrder";
import ConstantValuesBigTableFactory from "../utils/bigTable/ConstantValuesBigTableFactory";
import {  HeaderDefinition } from '../utils/bigTable/definition/HeaderDefinition';
import { DataSource } from '../utils/bigTable/definition/DataSource';
import OrderDetails from './OrderDetails';
import InoviceData from './OrdersInvoiceData';
import { t } from 'i18next';
import { DOWNLOAD_INVOICE, INVOICING_AND_PAYMENT, LICENSE_NAME, ORDER_ID, ORDER_TIME, PRODUCT_NAME, RowData, SHOW_BASKET, STATUS, TOTAL, UPLOAD_DOCUMENT, UserOrderTableColumns } from './UserOrderTableColumns';

import i18n from '../../i18n';
import { useOrganizations } from '../../hooks/useOrganizations';

type UserOrderTableParams = {
    aseclaAdminModule: boolean,
    orders: UserOrder[],
    refresh?: () => void
}
function UserOrderTable({orders, aseclaAdminModule, refresh} : UserOrderTableParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    const { organizations } = useOrganizations();

    const [rowData, setRowData] = React.useState<RowData[]>([]);
    const [orderToShowDetails, setOrderToShowDetails] = React.useState<UserOrder|null>(null);
    const [orderToConfirmInvoiceData, setOrderToConfirmInvoiceData] = React.useState<UserOrder|null>(null);
    const [searchPattern, setSearchPattern] = React.useState<string>("");
    const [filteredOrders, setFilteredOrders] = React.useState<UserOrder[]>(orders);

    let columnsToShowByDefault = aseclaAdminModule
        ? [ORDER_ID, PRODUCT_NAME, LICENSE_NAME, TOTAL, DOWNLOAD_INVOICE, SHOW_BASKET]
        : [ORDER_ID, PRODUCT_NAME, LICENSE_NAME, ORDER_TIME, STATUS, INVOICING_AND_PAYMENT, DOWNLOAD_INVOICE, UPLOAD_DOCUMENT, SHOW_BASKET];
    const [allHeaders, ] = React.useState<HeaderDefinition[]>(UserOrderTableColumns(aseclaAdminModule, props.isManager(), false, setOrderToConfirmInvoiceData, setOrderToShowDetails, columnsToShowByDefault));
    let tableActions: any = {};

    React.useEffect(() => {
        setRowData(filteredOrders.map(order => {
            let res: RowData = {};
            let invoice = getLastInvoice(order);
            if (invoice) {
                res.dateOfPayment = invoice.dateOfPayment == undefined ? undefined : {date: invoice.dateOfPayment, value: invoice.dateOfPayment};
            }
            return res;
        }));
    }, [orders, filteredOrders]);

    const filterOrder = (filter: string) => {
        let upperFilter = filter.toUpperCase();
        setFilteredOrders(orders.filter(order => 
               (order.orderId + "").toUpperCase().indexOf(upperFilter) !== -1
            || order.items.filter(item => (
                   item.licenseType.names[i18n.language].toUpperCase().indexOf(upperFilter) !== -1
                || item.licenseType.product.names[i18n.language].toUpperCase().indexOf(upperFilter) !== -1
               )).length > 0
        ).sort((order1, order2) => (order1.orderTime ?? new Date()) < (order2.orderTime ?? new Date()) ? -1 : 1));
    }


    let data: DataSource = {
        rowData: rowData,
        setRowData: (rowNo: number, row: any) => {
            let newRowData = [...rowData];
            newRowData[rowNo] = row;
            setRowData(newRowData);
        },
    }

    if (orderToShowDetails != null) {
        return <OrderDetails cancelUserOrder={() => setOrderToShowDetails(null)} userOrder={orderToShowDetails} aseclaAdminModule={aseclaAdminModule} tableHeaders={allHeaders}></OrderDetails>
    }
    if (orderToConfirmInvoiceData != null) {
        return <InoviceData cancelUserOrder={() => {if (refresh) {refresh()}; setOrderToConfirmInvoiceData(null)}} userOrder={orderToConfirmInvoiceData}></InoviceData>
    }

    const [curOrganization, errCheck] = props.getOrganizationWithChecks();
    if (errCheck != null) {
        return errCheck;
    }

    let multipleOrganizations: boolean = organizations !== null && organizations.length > 1;

    return <div className="no-break">
        {!aseclaAdminModule && <>
            <div className="titlebar">{t("Orders of organization") as string}{multipleOrganizations && <>: {curOrganization!.name}</>}</div>
            <br/>
        </>}
        <div style={{textAlign: "right"}}>
            {t("Search") as string}:
            <input
                placeholder={t("Search")}
                value={searchPattern}
                onChange={e => {filterOrder(e.target.value); setSearchPattern(e.target.value); tableActions.resetPagination();}}
            />
        </div>
        <br></br>
        <ConstantValuesBigTableFactory
            dataSource={data}
            allHeaders={allHeaders}
            objects={filteredOrders}
            initPageSize={10}
            actions={tableActions}
        ></ConstantValuesBigTableFactory>
    </div>
}
export default UserOrderTable;