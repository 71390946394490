import { useTranslation } from "react-i18next";

type UnitDivParams = {
    lengthUnit?: string ;
    lowercase?: boolean;
}

function UnitDiv({lengthUnit, lowercase} : UnitDivParams) {
    const {t} = useTranslation();

    if (lengthUnit === undefined) return <></>

    const applyCase = (txt: string): string => {
        if (lowercase) {
            return txt.toLowerCase();
        } else {
            return txt;
        }
    }
    
    return <>
    {lengthUnit === "Day" && <>{applyCase(t("Days"))}</>}
    {lengthUnit === "Month" && <>{applyCase(t("Months")) as string}</>}
    {lengthUnit === "Year" && <>{applyCase(t("Years")) as string}</>}
    </>
}

export default UnitDiv;