import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { useTranslation } from 'react-i18next';
import { LicenseType } from "../../type/LicenseType";
import { BonusCode } from "../../type/BonusCode";
import VerifyBonusCodeResponse from "../../type/responses/VerifyBonusCodeResponse";
import AseclaButton from "./AseclaButton";
import { requestServer } from "../../store/ServerConnection";
import VerifyBonusCodeRequest from "../../type/request/VerifyBonusCodeRequest";
import { OrganizationData } from "../../type/responses/OrganizationData";

type BonusCodeElementParams = {
    licType: LicenseType,
    bonusCodeToForce?: BonusCode,
    setBonusCodeEnableBuy: (enable: boolean) => void,
    setBonusCodeValid: (valid: boolean) => void,
    setBonusCode?: (bonusCode: BonusCode) => void,
    bonusCode: BonusCode | null,
    includeConsent?: boolean,
    includeBonusCodeInput?: boolean,
}
function BonusCodeElement({licType, setBonusCodeEnableBuy, setBonusCodeValid, bonusCodeToForce, bonusCode, setBonusCode, includeConsent = true, includeBonusCodeInput = true} : BonusCodeElementParams) {    
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const {t} = useTranslation();

    const [bonusCodeString, setBonusCodeString] = React.useState<string>(bonusCodeToForce?.theCode ?? bonusCode?.theCode ?? "");
    const [showBonusCodeMsg, setShowBonusCodeMsg] = React.useState<boolean>(false);
    const [bonusCodeConsent, setBonusCodeConsent] = React.useState<boolean>(false);
    const [showVerify, setShowVerify] = React.useState<boolean>(false);

    React.useEffect(() => {
        setBonusCodeEnableBuy(bonusCodeString === "" || ((bonusCodeToForce?.theCode === bonusCodeString || showBonusCodeMsg) && bonusCode !== null && (!includeConsent || bonusCodeConsent)));
        setBonusCodeValid((bonusCode !== null || bonusCodeToForce != undefined) && bonusCodeString === (bonusCodeToForce?.theCode ?? bonusCode?.theCode));
    }, [bonusCodeString, showBonusCodeMsg, bonusCodeConsent, bonusCode])

    const onBonusCodeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let newCode = e.target.value;
        setBonusCodeString(newCode);
        setShowBonusCodeMsg(bonusCode !== null && newCode === bonusCode.theCode);
        setShowVerify(newCode !== "" && (bonusCode === null || newCode !== bonusCode.theCode));
    }

    const verifyBonusCode = (licenseType: LicenseType) => {
        let org: OrganizationData | null = props.getCurrentOrganization();
        let req: VerifyBonusCodeRequest = {"bonusCodeText": bonusCodeString, licenseId: licenseType.id, organizationId: org == null ? 0 : org.id};
        requestServer("verifyBonusCode", req, props).then((ret: VerifyBonusCodeResponse) => {
            if (setBonusCode != undefined) setBonusCode(ret.bonusCode);
            setShowBonusCodeMsg(true);
            setShowVerify(false);
        });
    }

    return <>
        {includeBonusCodeInput && <>
            {t("Would you like to provide bonus code")} <input id="bonusCodeInput" type="text" value={bonusCodeString} onChange={onBonusCodeChange} disabled={bonusCodeToForce != undefined || props.freezeHeaderCounter > 0}></input>
            {showVerify && <AseclaButton id="verifyBonusCode" action={(e) => verifyBonusCode(licType)}>{t("Verify") as string}</AseclaButton>}
            {showBonusCodeMsg && <>
                <br></br>
                {bonusCode === null && <i id="bonusCodeIncorrect">{t("Provided bonus code is incorrect") as string}</i>}
                {bonusCode !== null && <i id="bonusCodeCorrect">{t("Bonus code correct")}: {bonusCode.bonusDescription["en"]}</i>}
            </>}
        </>}
        {includeConsent && bonusCode !== null && <div>
            <input type="checkbox" disabled={bonusCodeString === "" || props.freezeHeaderCounter > 0} checked={bonusCodeConsent} onChange={e => setBonusCodeConsent(e.target.checked)} name="bonusCodeConsent" id="bonusCodeConsent"/>
            &nbsp;
            <label htmlFor="bonusCodeConsent">{t("I have read the terms of the promotion") as string}</label>
        </div>}
    </>
}
export default BonusCodeElement;