import React from "react";
import AseclaDataContext from "../../../store/AseclaDataContext";
import { ContextProps } from "../../../type/ContextProps";
import { Trans, useTranslation } from 'react-i18next';
import { BonusCode } from "../../../type/BonusCode";
import AseclaButton from "../../utils/AseclaButton";
import Multiselect from 'multiselect-react-dropdown';
import { dateToString } from "../../utils/functions";
import { useAdminProducts } from "../../../hooks/queries/useAdminProducts";
import Loading from "../../utils/Loading";
import IsErrorMessage from "../../utils/IsErrorMessage";
import { useDeleteBonusCode, useSaveBonusCode } from "../../../hooks/mutations/useAdminBonusCodeItem";

type BonusCodeParams = {
    bonusCode: BonusCode,
    index: number,
    saveCode: (bonusCode: BonusCode) => void
    deleteCode: () => void
}
function BonusCodeItem({bonusCode, index, saveCode, deleteCode}: BonusCodeParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    const {t } = useTranslation();
    const [expanded, setExpanded] = React.useState<boolean>(false);

    const { products, isFetching, isError } = useAdminProducts();
    const { saveBonusCode } = useSaveBonusCode(bonusCode, saveCode);
    const { deleteBonusCode } = useDeleteBonusCode(deleteCode);

    const duplicateBonusCode = (bc: BonusCode): BonusCode => {
        let dup: BonusCode = {...bonusCode};
        if (bc.products) {
            dup.products = [...bc.products];
        } else {
            dup.products = [];
        }
        dup.bonusDescription = {... bc.bonusDescription};
        return dup;
    }

    const setDiscountType = (type: string) => {
        let bc: BonusCode = duplicateBonusCode(bonusCode);
        bc.discountType = type;
        saveCode(bc);
    }
    const setDiscountCode = (theCode: string) => {
        let bc: BonusCode = duplicateBonusCode(bonusCode);
        bc.theCode = theCode;
        saveCode(bc);
    }
    const setBonusCodeValue = (fieldName: string, value: any) => {
        let bc: any = duplicateBonusCode(bonusCode);
        bc[fieldName] = value;
        saveCode(bc);
    }
    const setBonusCodeDescription = (lang: string, value: string) => {
        let bc: BonusCode = duplicateBonusCode(bonusCode);
        bc.bonusDescription[lang] = value;
        saveCode(bc);
    }

    let productValues: any[] = [];
    let allProducts: any[] = [];
    for (let i = 0; i < products.length; i++) {
        let prd = {name: products[i].names['en'], id: products[i].id, index: i}
        if (bonusCode.products && bonusCode.products.indexOf(products[i].id) != -1) {
            productValues.push(prd);
        }
        allProducts.push(prd);
    }

    const onSelect = (selectedList: any, selectedItem: any) => {
        let bc: BonusCode = duplicateBonusCode(bonusCode);
        bc.products.push(selectedItem.id);
        saveCode(bc);
    }
    const onRemove = (selectedList: any, removedItem: any) => {
        let bc: BonusCode = duplicateBonusCode(bonusCode);
        let index = bc.products.indexOf(removedItem.id);
        bc.products.splice(index, 1);
        saveCode(bc);
    }

    if (isFetching) return <Loading></Loading>
    if (isError) return <IsErrorMessage></IsErrorMessage>

    return <div>
        ({bonusCode.id})<AseclaButton action={e => setExpanded(!expanded)}>{t(expanded ? "Less information" : "More information") as string}</AseclaButton>
        {!expanded && <>{t("Bonus Code") as string}: {bonusCode.theCode}</>}
        {expanded && <>
            <AseclaButton action={saveBonusCode}>{t("Save") as string}</AseclaButton>
            <AseclaButton action={e => deleteBonusCode({bonusCodeId: bonusCode.id})}>{t("Delete") as string}</AseclaButton>
            <input type="checkbox" name={"isActive" + bonusCode.id} id={"isActive" + bonusCode.id} checked={bonusCode.active} onChange={e => setBonusCodeValue("active", e.target.checked)}/><label htmlFor={"isActive" + bonusCode.id}>{t("Active") as string}</label>
            <div>
                {t("Bonus Code") as string}: <input type="text" value={bonusCode.theCode||""} onChange={e => setDiscountCode(e.target.value)}/>
            </div>
            <div>
                <input type="radio" name={"value_perc_" + index} value="Percentage" onChange={e => setDiscountType("Percentage")} checked={bonusCode.discountType==="Percentage"} />{t("Percent discount") as string}
                <input type="radio" name={"value_const_" + index} value="ConstantValue" onChange={e => setDiscountType("ConstantValue")} checked={bonusCode.discountType==="ConstantValue"}/>{t("Constant value discount") as string}
                <input type="number" value={bonusCode.value||""} onChange={e => setBonusCodeValue("value", Number(e.target.value))} />
                {bonusCode.discountType == "Percentage" && <>%</>}
                {bonusCode.discountType == "ConstantValue" && <>zł</>}
            </div>
            <div>
                {/* https://stackoverflow.com/questions/30190588/html-select-multiple-as-dropdown */}
                <Trans i18nKey={"limitUsageTo"}
                    components={{limit: <input type="number" value={bonusCode.limitNoOfUsage||""} onChange={e => setBonusCodeValue("limitNoOfUsage", Number(e.target.value))}/>}}/>
                <br></br>
                {t("Valid until") as string}: <input type="date" value={dateToString(bonusCode.validUntil)} onChange={e => setBonusCodeValue("validUntil", new Date(e.target.value))}/>
            </div>


            <Multiselect
                options={allProducts} // Options to display in the dropdown
                selectedValues={productValues} // Preselected value to persist in dropdown
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                placeholder={t("Select applicable products")}
            />

            <div>
                {t("Bonus Codes user description") as string}:
                {props.getGUILangs().map((lang: string) => <div key={"lang_" + lang + "_" + index}>
                    {lang}: <input type="text" value={bonusCode.bonusDescription[lang]} onChange={e => setBonusCodeDescription(lang, e.target.value)}/>
                </div>)}
            </div>
        </>}
    </div>
}
export default BonusCodeItem;