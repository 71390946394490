import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { t } from "i18next";

interface IsManagerParams {
    children: JSX.Element,
    force?: boolean,
    invert?: boolean
}
function IsManager({children, invert = false, force = false}: IsManagerParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    const isManager: boolean = invert !== (props.getCurrentOrganization() != null && (props.getCurrentOrganization()?.haveManagerRights ?? false));
    if (isManager) {
    }

    return <>
        {(force || isManager) && children}
        {(!isManager) && <p><b><big>{t("You need to be manager in your organization to make an order.") as string}</big></b></p>}
    </>
}

export default IsManager;