import { useTranslation } from 'react-i18next';
import { BonusCode } from "../../../type/BonusCode";
import BonusCodeItem from "./BonusCodeItem";
import AseclaButton from "../../utils/AseclaButton";
import { TANSTACK_ADMIN_BONUS_CODE_KEY, useAdminBonusCodes } from "../../../hooks/queries/useAdminBonusCodes";
import Loading from "../../utils/Loading";
import IsErrorMessage from "../../utils/IsErrorMessage";
import { queryClient } from "../../../App";
import React from 'react';

function BonusCodes() {
    const {t } = useTranslation();
    const [showInactive, setShowInactive] = React.useState<boolean>(false);

    const { bonusCodes, isFetching, isError } = useAdminBonusCodes(!showInactive);

    const createNew = () => {
        queryClient.invalidateQueries({queryKey: TANSTACK_ADMIN_BONUS_CODE_KEY(showInactive)});
        queryClient.setQueryData(TANSTACK_ADMIN_BONUS_CODE_KEY(!showInactive), [...bonusCodes, {
            id: -1,
            discountType: "Percentage",
            bonusDescription: {},
            products: [] as number[],
            active: true
        } as BonusCode]);
    }

    const saveCode = (index: number, bonusCode: BonusCode): void => {
        let arr: BonusCode[] = [...bonusCodes];
        arr[index] = bonusCode;
        queryClient.invalidateQueries({queryKey: TANSTACK_ADMIN_BONUS_CODE_KEY(showInactive)});
        queryClient.setQueryData(TANSTACK_ADMIN_BONUS_CODE_KEY(!showInactive), arr);
    }

    const deleteCode = (index: number): void => {
        let arr: BonusCode[] = [...bonusCodes];
        arr.splice(index, 1);
        queryClient.invalidateQueries({queryKey: TANSTACK_ADMIN_BONUS_CODE_KEY(showInactive)});
        queryClient.setQueryData(TANSTACK_ADMIN_BONUS_CODE_KEY(!showInactive), arr);
    }

    const switchShowActive = (showActive: boolean) => {
        setShowInactive(showActive);
    }

    if (isFetching) return <Loading></Loading>
    if (isError) return <IsErrorMessage></IsErrorMessage>

    return <>
        <h1 className="asecla-heading">{t("Bonus codes") as string}</h1>
        <AseclaButton action={e => createNew()}>{t("New Bonus Code") as string}</AseclaButton>
        <input type="checkbox" name="showInactive" id="showInactive" checked={showInactive} onChange={e => switchShowActive(e.target.checked)}/><label htmlFor='showInactive'>{t("Show inactive bonus codes") as string}</label>
        {bonusCodes.map((bonusCode, index) => {
            return <BonusCodeItem 
                key={"bonusCode" + index}
                index={index}
                bonusCode={bonusCode}
                saveCode={(code: BonusCode) => saveCode(index, code)}
                deleteCode={() => deleteCode(index)}
            />
        })}
    </>
}
export default BonusCodes;