import React from 'react';
import { UserOrder } from '../../type/UserOrder';
import { UserOrderItem } from '../../type/UserOrderItem';
import { CellProperty, HeaderDefinition } from '../utils/bigTable/definition/HeaderDefinition';
import { DataSource } from '../utils/bigTable/definition/DataSource';
import ConstantValuesBigTableFactory from '../utils/bigTable/ConstantValuesBigTableFactory';
import { useTranslation } from 'react-i18next';
import AseclaButton from '../utils/AseclaButton';
import PropertiesDisplay, { PropertyDisplayElement } from './PropertiesDisplay';
import { ContextProps } from '../../type/ContextProps';
import AseclaDataContext from '../../store/AseclaDataContext';
import { BONUS_CODE, DATE_OF_PAYMENT, DOWNLOAD_INVOICE, HISTORY_ACTION_OWNER, HISTORY_CHANGE_DATE, HISTORY_COMMENT, INVOICING_AND_PAYMENT, LICENSE_NAME, LICENSE_TECHNICAL_NAME, ORDER_ID, ORDER_PRICE, ORDER_TIME, ORGANIZATION, ORGANIZATION_DISCOUNT, PRODUCT_NAME, PRODUCT_TECHNICAL_NAME, STATUS, TOTAL, UPLOAD_DOCUMENT, USER_ID, UserOrderTableColumns } from './UserOrderTableColumns';
import { useUserOrderHistory } from '../../hooks/useUserOrders';


const predefineHeaders = (): HeaderDefinition[] => {
    const {t} = useTranslation();
    let headers: HeaderDefinition[] = [];

    const defineTableHeader = (props: HeaderDefinition) => {
        headers.push(props);
    }

    const get = (getValue: (item: UserOrderItem) => any): (cellProperty: CellProperty, dataSource: DataSource) => any => {
        return (cellProperty: CellProperty, dataSource: DataSource) => getValue(cellProperty.object);
    }

    defineTableHeader({headerKey: "PRODUCT_NAME", display: true, label: t("Product"), getCellContent: get((item) => item.licenseType.product?.names["en"])});
    defineTableHeader({headerKey: "LICENSE_NAME", display: true, label: t("License"), getCellContent: get((item) => item.licenseType.names["en"])});
    defineTableHeader({headerKey: "PRICE", display: true, label: t("Net price per License"), getCellContent: get((item) => (item.price/item.noOfUsers) + "zł")});
    defineTableHeader({headerKey: "QUANTITY", display: true, label: t("Quantity"), getCellContent: get((item) => item.noOfUsers)});
    defineTableHeader({headerKey: "SUBTOTAL", display: true, label: t("Subtotal") + " " + t("net"), getCellContent: get((item) => item.price + "zł")});

    return headers;
}


function OrderProperties({aseclaAdminModule, userOrder}: any) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const [orderProperties, ] = React.useState<HeaderDefinition[]>(UserOrderTableColumns(aseclaAdminModule, props.isManager(), false, ()=>{}, ()=>{}, []));

    let propertiesHeaderDefinition = (key: String): HeaderDefinition|undefined => {
        for (let i = 0; i < orderProperties.length; i++) {
            if (orderProperties[i].headerKey === key) {
                return orderProperties[i];
            }
        }
        return undefined;
    }

    let propertiesToDisplay: PropertyDisplayElement[] = [];
    const addProperty = (key: String, adminSpecific: boolean = false) => {
        let prop: HeaderDefinition|undefined = propertiesHeaderDefinition(key);
        if (prop !== undefined && !prop.alwaysHide) {
            let cellContent: any = prop.getCellContent({
                object: userOrder,
                rowNo: 0
            }, {rowData: [userOrder]});
            propertiesToDisplay.push({name: prop.label, value: cellContent, className: adminSpecific ? "asecla-admin" : ""});
        }
    }
    addProperty(ORDER_ID);
    addProperty(ORDER_TIME);
    addProperty(USER_ID);
    addProperty(TOTAL);
    addProperty(STATUS);
    addProperty(ORGANIZATION, true);
    if (aseclaAdminModule || props.isManager()) {
        addProperty(ORGANIZATION_DISCOUNT);
    }
    addProperty(LICENSE_NAME);
    addProperty(LICENSE_TECHNICAL_NAME, true);
    addProperty(PRODUCT_NAME);
    addProperty(PRODUCT_TECHNICAL_NAME, true);
    addProperty(ORDER_PRICE);
    addProperty(BONUS_CODE);
    addProperty(DOWNLOAD_INVOICE);
    
    addProperty(UPLOAD_DOCUMENT, true);
    addProperty(DATE_OF_PAYMENT);
    addProperty(INVOICING_AND_PAYMENT, true);

    return <PropertiesDisplay properties={propertiesToDisplay}></PropertiesDisplay>;
}

function OrderHistory({aseclaAdminModule, userOrder}: any) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const userOrderHistory = useUserOrderHistory([userOrder], props.getCurrentOrganization()?.id!)[0];
    const [tableHeaders, ] = React.useState<HeaderDefinition[]>(UserOrderTableColumns(aseclaAdminModule, props.isManager(), true, ()=>{}, ()=>{}, 
        aseclaAdminModule
            ? [HISTORY_ACTION_OWNER, HISTORY_CHANGE_DATE, HISTORY_COMMENT, STATUS]
            : [HISTORY_CHANGE_DATE, HISTORY_COMMENT, STATUS]
    ));

    React.useEffect(() => {
        userOrderHistory.refetch();
    }, [])

    let data: DataSource = {
        rowData: [userOrderHistory],
    }

    return <ConstantValuesBigTableFactory
        dataSource={data}
        allHeaders={tableHeaders}
        objects={userOrderHistory.history}
        initPageSize={10}
    ></ConstantValuesBigTableFactory>
}


type OrderDetailsParams = {
    userOrder: UserOrder,
    cancelUserOrder: () => void,
    aseclaAdminModule: boolean,
    tableHeaders: HeaderDefinition[],
}
function OrderDetails({userOrder, cancelUserOrder, aseclaAdminModule, tableHeaders}: OrderDetailsParams) {
    const {t} = useTranslation();
    const [allHeaders, ] = React.useState<HeaderDefinition[]>(predefineHeaders());
    
    let otherHeaderButtons = [
        <AseclaButton className="small" action={e => cancelUserOrder()}>{t("Back to order list") as string}</AseclaButton>
    ]

    return <div>
        <div className="titlebar">{t("Details of order") as string} {userOrder.orderId}</div>
        <br></br>
            
        <ConstantValuesBigTableFactory
                otherHeaderButtons={otherHeaderButtons}
                dataSource={{}}
                allHeaders={allHeaders}
                objects={userOrder.items}
                initPageSize={100}
                otherProps={{makeItHeight: false}}
        ></ConstantValuesBigTableFactory>

        <OrderProperties aseclaAdminModule={aseclaAdminModule} userOrder={userOrder}></OrderProperties>

        <OrderHistory aseclaAdminModule={aseclaAdminModule} userOrder={userOrder} tableHeaders={tableHeaders}></OrderHistory>
    </div>
}

export default OrderDetails;