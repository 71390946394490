import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';

const loadCommons = () => {
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_COMMONS_SCRIPT_URL || "";
    script.async = true;
    script.defer = true;
    console.log("Loading ", process.env.REACT_APP_COMMONS_SCRIPT_URL);
    document.head.appendChild(script);
}

const waitForAseclaLoader = (timeout = 10000, interval = 100) => {
    return new Promise((resolve, reject) => {
      const startTime = Date.now();
  
      const checkLoader = () => {
        if ((window as any).ASECLA_COMMONS_LOADER) {
          resolve((window as any).ASECLA_COMMONS_LOADER);
        } else if (Date.now() - startTime > timeout) {
          reject(new Error('Timeout waiting for ASECLA_COMMONS_LOADER'));
        } else {
          setTimeout(checkLoader, interval);
        }
      };
  
      checkLoader();
    });
};

loadCommons();

waitForAseclaLoader().then(() => {
    (window as any).KEYCLOAK_AUTH_URL = process.env.REACT_APP_KEYCLOAK_URL;
    (window as any).ASECLA_COMMONS_LOADER.onReady(() => {
        const reactRoot = ReactDOM.createRoot(
            document.getElementById('reactRoot') as HTMLElement
        );
        reactRoot.render(
            // <React.StrictMode>
            <App />
            // </React.StrictMode>
        );
    });    

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
});

