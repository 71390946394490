import PageStructure from "../component/PageStructure";
import { t } from "i18next";
import React from "react";
import { ContextProps } from "../type/ContextProps";
import AseclaDataContext from "../store/AseclaDataContext";
import AseclaButton from "../component/utils/AseclaButton";
import { useRedeemCode } from "../hooks/mutations/useRedeemCode";
import { queryClient } from "../App";
import { TANSTACK_USER_ORDERS_KEY } from "../hooks/useUserOrders";
import { MY_ROLE_KEY } from "../hooks/queries/useMyRole";

function RedeemCodePage() {
    let [code, setCode] = React.useState<string>("");
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const {redeemCode} = useRedeemCode({
        successAction: () => {
            setCode("");
            queryClient.invalidateQueries(["ORGANIZATIONS"]);
            queryClient.invalidateQueries([TANSTACK_USER_ORDERS_KEY]);
            queryClient.invalidateQueries([MY_ROLE_KEY]);
        }
    });

    return <PageStructure>
        <h2 className="asecla-heading">{t("Redeem code") as string}</h2>
        <div>{t("Enter gift card or promo code") as string}</div>
        <input name="code" id="code" placeholder={t("Your code")} value={code} onChange={(e) => setCode(e.target.value)} disabled={props.freezeHeaderCounter > 0}/>
        <AseclaButton action={e => redeemCode({code: code})}>{t("Redeem code") as string}</AseclaButton>
    </PageStructure>
}
RedeemCodePage.CONTEXT_PATH = "redeemCode";

export default RedeemCodePage;