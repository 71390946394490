import { t } from "i18next";
import { LicenseType } from "../../../type/LicenseType";
import UnitDiv from "../../utils/UnitDiv";
import Price from "../Price";
import MultipleOrganizationsCheck from "../MultipleOrganizationsCheck";
import { PropertyQuestion } from "../PropertyQuestion";
import i18n from "../../../i18n";
import { ContextProps } from "../../../type/ContextProps";
import React from "react";
import AseclaDataContext from "../../../store/AseclaDataContext";
import LogInOrRegister from "../LogInOrRegister";
import './AseclaOfferCustomizeProduct.css';
import AseclaButton from "../../utils/AseclaButton";
import { Trans } from "react-i18next";

interface AseclaOfferCustomizeProductProps {
    setQuantity: (quantity: number) => void,
    setLength: (length: number) => void,
    quantity: number | undefined,
    length: number,
    licType: LicenseType,
    licTypePropValue: Array<PropertyQuestion>,
    setLicTypePropValue: React.Dispatch<React.SetStateAction<Array<PropertyQuestion>>>,
  
    setPropertiesAccepted: React.Dispatch<React.SetStateAction<boolean>>,
    allAvailableLicenses: LicenseType[],
    switchSelectedLicenseTypeTo: (licenseTypeNo : number) => void ,
}
function AseclaOfferCustomizeProduct({setQuantity, setLength, length, quantity, licType, licTypePropValue, setLicTypePropValue,
            setPropertiesAccepted, allAvailableLicenses, switchSelectedLicenseTypeTo}: AseclaOfferCustomizeProductProps) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    const setPropertyValue = (propertyNo : number, value: string): void => {
        let arr = [...licTypePropValue];
        arr[propertyNo].value = value;
        setLicTypePropValue(arr);
    }
    
    return <div id="aseclaOffer" className="fullwidth">
        <p>{t("Thank you for trusting our product. We are looking forward for you customer satisfaction - not only because of our product quality, but also our support and service.") as string}</p>
        <h3 className="asecla-heading">{t("Please configure the license for selected product:") as string}</h3>

        <div className="configParent">
            <div className="configurationContainer shadow-box">
                <label>{t("Product") as string}</label>
                <select className="small" onChange={e => {switchSelectedLicenseTypeTo(Number.parseInt(e.target.value))}} value={licType.id}>
                    {allAvailableLicenses.map(lic => 
                        <option key={lic.id} value={lic.id}>{lic.names[i18n.language]}</option>
                    )}
                </select>

                <label>{t("Quantity") as string}</label>
                <input type="number"
                    max={licType.maxQuantity === 0 ? undefined : licType.maxQuantity}
                    value={quantity}
                    min={licType.minQuantity === 0 ? 1 : licType.minQuantity}
                    onChange={e => setQuantity(Number(e.target.value))}
                    id="quantity"
                    name="quantity"/>

                <label>{t("Length") as string}</label>
                <span>
                    <input type="number"
                        value={length}
                        onChange={e => setLength(Number(e.target.value))}
                        id="length"
                        name="length"
                        min={licType.minLength > 0 ? licType.minLength : 1}
                        max={licType.maxLength > 0 ? licType.maxLength : undefined}
                        disabled={(licType.minLength > 0 ? licType.minLength : 1) === (licType.maxLength > 0 ? licType.maxLength : undefined)}
                    />&nbsp;
                    <UnitDiv lengthUnit={licType.lengthUnit}></UnitDiv>
                </span>

            </div>
        </div>
        <p></p>
        <div id="costDiv" className={"fullwidth"}>
            {t("Total") as string}: <b><Price length={length} licenseType={licType!} quantity={quantity!} showDiscount={true} showHint={true} displayNetAndBrutto={true}></Price></b>
            <div>
                {t("If you have bonuscode, you can apply it in final order step") as string}
            </div>
        </div>
        <div>
            <Trans i18nKey="how_to_buy_description"
                            components={{price: <Price length={length} licenseType={licType!} quantity={quantity!} showDiscount={false} showHint={false}></Price>,
                                         unit: <UnitDiv lengthUnit={licType.lengthUnit} lowercase={true}></UnitDiv>,
                                        }}
                            values={{length: length,
                                     licenseName: licType !== undefined ? licType.names[i18n.language] : "",
                            }}
            ></Trans>
        </div>


        
        {licTypePropValue.map((property, index) => <div key={"property_" + index}>
            <input value={property.value} onChange={(e) => setPropertyValue(index, e.target.value)} disabled={props.freezeHeaderCounter > 0}/>
            {licType !== undefined ? licType.product.properties[property.productPropertyIndex].labels[i18n.language] : ""}<br></br>
        </div>)}


        {!props.authentication.isAuthenticated() &&
            <LogInOrRegister
                licType={licType}
                length={length}
                lengthUnit={licType.lengthUnit}
                licTypePropValue={licTypePropValue}
                quantity={quantity}
            ></LogInOrRegister>
        }
        {props.authentication.isAuthenticated() &&
            <MultipleOrganizationsCheck checkCurrentOrganization={false}>
                <div id="proceedToSummaryCTA">
                    <AseclaButton className="cta"
                            action={(e) => setPropertiesAccepted(true)}
                            enabled={props.getCurrentOrganization() == null || props.getCurrentOrganization()?.haveManagerRights}>
                        {t("Proceed to order summary") as string}
                    </AseclaButton>
                </div>
            </MultipleOrganizationsCheck>
        }
        
        {allAvailableLicenses.length > 0 && <div className="otherProducts">
            <h5 className="asecla-heading">{t("Our other products:") as string}</h5>
            <table className="asecla-table">
                <thead>
                    <tr>
                        {allAvailableLicenses.filter(lic => licType == undefined || lic.id != licType.id).map(otherLicType => <th key={'th_' + otherLicType.id}>
                                <h5 className="asecla-heading">{otherLicType.names[i18n.language]}</h5>
                            </th>)}                    
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {allAvailableLicenses.filter(lic => licType == undefined || lic.id != licType.id).map(otherLicType => <td key={otherLicType.id} className="shadow-box">
                            <div className="licTypeDescription" dangerouslySetInnerHTML={{__html: otherLicType.descriptions[i18n.language]}}></div>
                            <AseclaButton action={()=>{window.location.replace('/aseclaOfferPage?tname=' + otherLicType.technicalName);}}>{t("Choose") as string}</AseclaButton>
                        </td>)}
                    </tr>
                </tbody>
            </table>
        </div>}
    </div>
}
export default AseclaOfferCustomizeProduct;