import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import AseclaOfferPage from "../../page/AseclaOfferPage";
import LicensesByOrderPage from "../../page/LicensesByOrders";
import ManageUsersPage from "../../page/ManageUsers";
import LicensesListPage from "../../page/LicensesListPage";
import LicensesDetailsPage from "../../page/LicensesDetailsPage";
import { OrganizationData } from "../../type/responses/OrganizationData";
import { useOrganizations } from "../../hooks/useOrganizations";

function HomeBanner() {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const {t} = useTranslation();
    const {organizations, isAseclaAdmin} = useOrganizations();

    let curOrganization : OrganizationData | null = props.currentOrganizationNo === -1 ? null : (organizations??[])[props.currentOrganizationNo];
    return <>
        <h1 className="asecla-heading">{t("Welcome to ASECLA") as string}{process.env.REACT_APP_BACKEND_ENVIRONMENT}</h1>
        <section>
            <h3 className="asecla-heading">{t("Pilot phase offer") as string}</h3>
            {t("Maybe you don't want to go through official procedure? Contact us, call us, write us - We need your input to make our systems even better!") as string}
            {/* {!props.authentication.authenticated && <div>{t("Please log in to manage your licenses") as string}</div>} */}
        </section>
 
        <ButtonMenu className="button-section">
            {curOrganization != null && curOrganization.haveManagerRights && 
                        <button className="cta asecla-button" onClick={e => props.openPage(AseclaOfferPage.CONTEXT_PATH + "?tname=silver")}>{t('Buy') as string}</button>}
            {curOrganization != null && (curOrganization.haveAdminRights || curOrganization.haveManagerRights) &&
                <button className="cta asecla-button" onClick={e => props.openPage(ManageUsersPage.CONTEXT_PATH)}>{t('Users') as string}</button>}
            {curOrganization != null && (curOrganization.haveAdminRights || curOrganization.haveManagerRights) &&
                <button className="cta asecla-button" onClick={e => props.openPage(LicensesListPage.CONTEXT_PATH)}>{t('Licenses') as string}</button>}
        </ButtonMenu>
        <ButtonMenu className="button-section">
            {curOrganization != null && curOrganization.haveManagerRights && 
                    <button className="cta asecla-button" onClick={e => props.openPage(LicensesByOrderPage.CONTEXT_PATH)}>{t('Orders') as string}</button>}
            {curOrganization != null && (curOrganization.haveAdminRights || curOrganization.haveManagerRights) &&
                    <button className="cta asecla-button" onClick={e => props.openPage("redeemCode")}>{t('Redeem code') as string}</button>}
            {curOrganization != null && (curOrganization.haveAdminRights || curOrganization.haveManagerRights) &&
                <button className="cta asecla-button" onClick={e => props.openPage(LicensesDetailsPage.CONTEXT_PATH)}>{t('Licenses details') as string}</button>}
        </ButtonMenu>
    </>
}
export default HomeBanner;

const ButtonMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  button {
    flex: 1;
    height: 150px; /* Adjust height as needed */
    margin: 5px; /* Adjust margin as needed */
    font-size: 18px; /* Adjust font size as needed */
  }
`;
