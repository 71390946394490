import React from 'react';
import AseclaDataContext from '../../store/AseclaDataContext';
import { ContextProps } from '../../type/ContextProps';
import { useTranslation } from 'react-i18next';
import ManageOrganizationsPage from '../../page/ManageOrganizationsPage';
import { OrganizationData } from '../../type/responses/OrganizationData';
import AseclaButton from '../utils/AseclaButton';
import OrganizationSwitchDropdown from './OrganizationSwitchDropdown';
import { useOrganizations } from '../../hooks/useOrganizations';
import { useNavigate } from 'react-router-dom';

function OrganizationSwitch() {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const {t} = useTranslation();
    const navigate = useNavigate();
  
    const {organizations, isError, isFetching} = useOrganizations();

    let curOrganization : OrganizationData | null = null;
    if (props.authentication.isAuthenticated()) {
        if (organizations != null) {
            curOrganization = props.currentOrganizationNo === -1 ? null : organizations[props.currentOrganizationNo];
        }
    }

    if (isError) {
        return <div className="small">{t("Could not load organization") as string}</div>;
    }
    return <div id="organizationSelection">
        {isFetching && <>{t("Loading organization...") as string}</>}
        {!isFetching && curOrganization != null && <div id="existingOrganizationsSelection">{t('Organization') as string}:
            <OrganizationSwitchDropdown enableCreation={true}></OrganizationSwitchDropdown>
        </div>}
        {!isFetching && (organizations == null || organizations.length === 0) && <div id="noOrganizationToSelect">{t("No organization message") as string} <AseclaButton action={(e) => navigate("/" + ManageOrganizationsPage.CONTEXT_PATH)}>{t("create it") as string}</AseclaButton></div>}
    </div>
}

export default OrganizationSwitch;