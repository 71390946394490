import { ReactI18NextChild } from 'react-i18next';
import BigTableHeader from './BigTableHeader';
import { PaginationParams } from './Pagination';
import { ExtraCellProperty, HeaderDefinition } from './definition/HeaderDefinition';
import React from 'react';
import { DataSource } from './definition/DataSource';
import './BigTable.css';

type BigTableParams = {
    dataSource: DataSource,
    allHeaders: HeaderDefinition[],
    loadData: (pageSize: number, pageNumber: number) => void,
    pageSize: number,
    setPageSize: (pageSize: number) => void,
    currentPageNo: number,
    setCurrentPageNo: (pageNo: number) => void,
    canNextPage: boolean,
    canPreviousPage: boolean,
    noOfPages: number|null,
    objects: any[],
    showPagination?: boolean,
    showPageSize?: boolean,
    otherHeaderButtons?: JSX.Element[],
    otherProps?: any,
}
/**
 * This function is to implement a nice big table on GUI. The data source is passed on param so
 * we actually eg don't know if all rows are loaded into memory or not.
 */
function BigTable({otherHeaderButtons, dataSource, allHeaders, loadData, pageSize, setPageSize,
         currentPageNo, setCurrentPageNo, canNextPage, canPreviousPage, noOfPages, objects,
         showPagination, showPageSize, otherProps}: BigTableParams) {
    const [displayColumn, setDisplayColumn] = React.useState<any>({});

    React.useEffect(() => {
        let display: any = {};
        for (let i = 0; i < allHeaders.length; i++) {
            let h: HeaderDefinition = allHeaders[i];
            if (h.display) {
                display[h.headerKey] = true
            }
        }
        setDisplayColumn(display);
    }, []);
    
    const switchColumnVisibility = (key: string): void => {
        let displayCol = {...displayColumn};
        displayCol[key] = !displayColumn[key];
        setDisplayColumn(displayCol);
    }

    const p: PaginationParams = {
        canNextPage: canNextPage,
        canPreviousPage: canPreviousPage,
        currentPageNo: currentPageNo,
        gotoPage: setCurrentPageNo,
        nextPage: () => loadData(pageSize, currentPageNo+1),
        previousPage: () => loadData(pageSize, currentPageNo-1),
        pageSize: pageSize,
        setCurrentPageNo: setCurrentPageNo,
        setPageSize: setPageSize,
        noOfPages: noOfPages,
        paginationData: {}
    }

    const display = (header: HeaderDefinition): boolean => {
        // return header.display;
        return displayColumn[header.headerKey];
    }

    const getTableBody = (): ReactI18NextChild[] => {
        let rows: ReactI18NextChild[] = [];

        for (let rowNo = currentPageNo * pageSize; rowNo < Math.min(objects.length, ((currentPageNo+1) * pageSize)); rowNo++) {
            let rowObject: any = objects[rowNo];
            rows.push(<tr key={"row" + rowNo} className={"mainRow"}>
                {allHeaders.map((header: HeaderDefinition, colNo: number) => {
                    if (!display(header)) return <React.Fragment key={"cell_" + rowNo + "_" + colNo}></React.Fragment>
                    let cellContent: any = header.getCellContent({
                        object: rowObject,
                        rowNo: rowNo
                    }, dataSource);
                    return <td className={dataSource.getCellClassname === undefined ? "" : dataSource.getCellClassname(colNo, rowNo)} onClick={e => {if (dataSource.onCellClick !== undefined) dataSource.onCellClick(rowNo, header)}} colSpan={header.getCellSpan?.(rowNo)} key={"cell_" + rowNo + "_" + colNo}>
                        {cellContent}
                    </td>
                })}
            </tr>);

            
            if (dataSource.expandRow !== undefined && dataSource.expandRow(rowNo, "") && dataSource.getExtraRowsNo !== undefined) {
                for (let extraRowNo = 0; extraRowNo < dataSource.getExtraRowsNo(rowNo); extraRowNo++) {
                    rows.push(<tr key={"extrarow" + rowNo + "_" + extraRowNo} className={"detailsRows"}>
                        {allHeaders.map((header: HeaderDefinition, colNo: number) => {
                            if (!display(header)) return <React.Fragment key={"cell_" + rowNo + "_" + colNo}></React.Fragment>
                            let extraCellProp: ExtraCellProperty = {
                                extraCellCol: colNo,
                                extraRowNo: extraRowNo,
                                object: rowObject,
                                rowNo: rowNo,
                            }
                            let extraCellContent: any = header.getExtraCellContent!(extraCellProp, dataSource);
                            if (extraRowNo % 2 == 0 && colNo > 1) return <React.Fragment key={"cell_" + rowNo + "_" + colNo}></React.Fragment>
                            return <td onClick={e => {if (dataSource.onExtraCellClick) dataSource.onExtraCellClick(extraCellProp)}} colSpan={header.getExtraCellSpan?.(colNo, rowNo, extraRowNo)} key={"cell_" + rowNo + "_" + colNo}>
                                {extraCellContent}
                            </td>
                        })}
                    </tr>);
                }        
            }
        }
        return rows;        
    }

    let makeItHeight: boolean = otherProps === undefined || otherProps.makeItHeight;

    return <div><BigTableHeader otherButtons={otherHeaderButtons} showPagination={showPagination} showPageSize={showPageSize} allHeaders={allHeaders} paginationParams={p} switchColumnVisibility={switchColumnVisibility}></BigTableHeader>
        <div className={makeItHeight ? "table-wrapper" : ""}>
            <table className="bigtable">
                <thead>
                    <tr>
                        {allHeaders
                            .filter((header: HeaderDefinition) => display(header))
                            .map((header: HeaderDefinition, colNo: number) => 
                                <th key={"header" + colNo}>{header.htmlDefinition ?? header.label}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {getTableBody()}
                </tbody>
            </table>
        </div>
    </div>
}

export default BigTable;
