import React, { ReactElement } from "react";
import { ContextProps } from "../type/ContextProps";
import { OrganizationData } from "../type/responses/OrganizationData";
import { LicenseType } from "../type/LicenseType";
import { StickBarMsg } from "../type/StickBarMsg";
import getAuthentication from "./AuthenticationPageBase";
import { HeaderIntegration, headerIntegration } from "./HeaderIntegration";
import { Pricing, pricingLogic } from "./Pricing";
import { PageBaseIntegration, pageBaseIntegration } from "./PageBaseIntegration";
import { useTranslation } from "react-i18next";
import { queryClient } from "../App";
import { useOrganizations } from "../hooks/useOrganizations";
import { NavigateFunction, useNavigate } from "react-router-dom";
import Loading from "../component/utils/Loading";
import { TANSTACK_USER_ORDERS_KEY } from "../hooks/useUserOrders";

export const AseclaDataContext = React.createContext<Partial<ContextProps>>({});

interface AseclaDataContextProviderParams {
  navigate?: NavigateFunction,
  children: any
}

export function AseclaDataContextProvider({children}: AseclaDataContextProviderParams) {
  const navigate = useNavigate();
  const {t} = useTranslation();

  //Interface to server
  const pricing: Pricing = pricingLogic();
  const pageBase: PageBaseIntegration = pageBaseIntegration();
  const authentication = getAuthentication();
  const header: HeaderIntegration = headerIntegration();

  const { organizations } = useOrganizations();

  //Currently used/selected organization (see dropdown in header). -1 means there is no header
  const [currentOrganizationNo, setCurrentOrganizationNo] = React.useState<number>(-1);
  //This is counter of current actions - used to freeze (disable) buttons while calling Server
  const [freezeHeaderCounter, setFreezeHeaderCounter] = React.useState<number>(0);
  //Messages shown to user on top bar (eg errors)
  const [stickBarMsgs, setStickBarMsgs] = React.useState<StickBarMsg[]>([]);

  const [disableOrganizationSwitch, setDisableOrganizationSwitch] = React.useState<boolean>(false);

  const freezeHeader = React.useCallback((freeze: boolean) => {
    setFreezeHeaderCounter(Math.max(0, freezeHeaderCounter + (freeze ? 1 : -1)));
  }, [freezeHeaderCounter]);

  const handleServerError = React.useCallback((e: any): void => {
    freezeHeader(false);
  }, [freezeHeader]);

  (window as any).ASECLA_COMMONS_LOADER.onReady(() => {
    header.checkLanguage();
    (window as any).ASECLA.Commons.Auth.getInstance().addStateChangeListener((auth: any) => {
      if (authentication.isAuthenticated()) {
        queryClient.invalidateQueries({ queryKey: [] })
        if (currentOrganizationNo !== -1) setCurrentOrganizationNo(-1);
      }
    });
  });

  async function getCurrentOrganizationName() {   
    return await ASECLA.Commons.Auth.getInstance().getUserCurrentOrganization();
  }

  React.useEffect(() => {
    header.setHeader();
    if (organizations !== null && organizations.length > 0 && currentOrganizationNo === -1) {
        getCurrentOrganizationName().then(org => {
            let rowNo = -1;
            for (let i = 0; i < organizations.length; i++) {
                if ((organizations[i].id + "") === org?.id) {
                    rowNo = i;
                }
            }
            setCurrentOrganizationNo(rowNo === -1 ? 0 : rowNo);
        });
    }
    if ((organizations === null || organizations.length === 0) && currentOrganizationNo > -1) {
      setCurrentOrganizationNo(-1);
    }
  }, [currentOrganizationNo, organizations, freezeHeader]);

  React.useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [TANSTACK_USER_ORDERS_KEY] })    
  }, [currentOrganizationNo, organizations]);  

  const getLicenseTypeDisplayName = (licenseType: LicenseType|null): string => { 
    if (licenseType === null) {
      return "";
    }
    let lang: string = pageBaseIntegration().getLangCode();
    let name: string = licenseType.names[lang];
    if (name !== undefined && name !== null && name !== "") {
      return name;
    }

    if (lang !== "en") {
      name = licenseType.names["en"];
      if (name !== undefined && name !== null && name !== "") {
        return name;
      }
    }

    return licenseType.technicalName;
  }

  const getCurrentOrganization = () : OrganizationData|null => {
    if (currentOrganizationNo === -1 || organizations == null) return null;
    return organizations[currentOrganizationNo];
  }

  const getOrganizationWithChecks = ():[org: OrganizationData|null, err: ReactElement|null] => {
    if (!authentication.initialized() || organizations == null || currentOrganizationNo == -1) {
        return [null, <Loading></Loading>]
    }
    if (!authentication.isAuthenticated()) {
      return [null, <div>{t("Please log in to see this page") as string}</div>]
    }
    let curOrganization: OrganizationData|null = getCurrentOrganization();
    if (curOrganization == null && organizations.length > 0) {
        return [null, <>{t("Please select organization to see the table")}</>]
    }
    return [curOrganization, null]
  }
  
  const isManager = (): boolean => {
    let org: OrganizationData|null = getCurrentOrganization();
    if (org == null) {
      return false;
    }
    return org.haveManagerRights;
  }
  const isUserAdmin = (): boolean => {
    let org: OrganizationData|null = getCurrentOrganization();
    if (org == null) {
      return false;
    }
    return org.haveAdminRights;
  }

  const openPage = (targetPage: string): void => {
    if (targetPage.startsWith("http")) {
        window.location.href = targetPage;
    } else {
        navigate("/" + targetPage);
    }
  }

  const changeOrganizationWithPropagationToCommons = (orgRowNo: number): void => {
    ASECLA.Commons.Auth.getInstance().setUserCurrentOrganization(organizations![orgRowNo].id + "").then(ret => {
        setCurrentOrganizationNo(orgRowNo);
    });
  }

  const context : ContextProps = {
    pricing,
    pageBaseIntegration: pageBase,
    handleServerError: handleServerError,

    authentication,

    getCurrentOrganization: getCurrentOrganization,
    getOrganizationWithChecks: getOrganizationWithChecks,
    freezeHeader,
    freezeHeaderCounter,

    getLicenseTypeDisplayName,

    stickBarMsgs, setStickBarMsgs,
    currentOrganizationNo: currentOrganizationNo, setCurrentOrganizationNo: changeOrganizationWithPropagationToCommons,
    getGUILangs: () => ["en", "pl"],
    isManager,
    isUserAdmin,
    openPage,
    disableOrganizationSwitch,
    setDisableOrganizationSwitch,
  };

  pricing.setContext(context);
  header.setContext(context);

  return <AseclaDataContext.Provider value={context}>
    {children}
  </AseclaDataContext.Provider>
}

export default AseclaDataContext;